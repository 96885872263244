import React from 'react';

import { FaStar } from 'react-icons/fa';
import Layout from '../components/layout';
import SEO from '../components/seo';

const dash = '\u2014';

const About = () => (
  <Layout>
    <SEO title="About Carlos" />
    <h2 className="about-title">About Carlos</h2>
    <div className="about-bio-wrapper">
      <img
        src="https://res.cloudinary.com/dferdwpfs/image/upload/q_auto/v1568066588/carloslopez/cbl_jlo_ocsbvs.jpg"
        alt="Carlos and Jennifer"
      />
      <p className="about-bio">
        Carlos and his wife Jennifer have four children and four grandchildren
        who live in the Austin area. Carlos attended Allen Junior High, A.S.
        Johnston High School, and ACC in Austin. His passion to serve and make a
        positive difference was instilled by his family values. “My desire to
        improve the lives of our kids, keep our aging community safe, and create
        social change in the area of domestic violence is what drives me.”
      </p>
    </div>
    <div className="about-wrapper">
      <div className="about-experience about-item">
        <h2>Experience that Matters</h2>
        <ul>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Constable, Travis County Precinct Five (2012{dash})
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Chief Deputy Constable, Travis County Constable Precinct Five
              (1981
              {dash}2012)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Instructor, Law Enforcement Management Institute of Texas, Sam
              Houston State University (2010{dash})
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Deputy Constable, Travis County Precinct Five (1980{dash}1981)
            </p>
          </li>

          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Instructor, Texas Justice Court Training Center at Texas State
              University (1980{dash})
            </p>
          </li>
        </ul>
      </div>
      <div className="about-professional-activities about-item">
        <h2>Professional Activities</h2>
        <ul>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              President, Justices of the Peace and Constables Association of
              Texas (2018)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Member, Executive Board of Directors of Texas Association of
              Counties (2018{dash})
            </p>
          </li>

          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Member, City of Austin Mayor&apos;s Committee on Institutional
              Racism and Systemic Inequalities (2017{dash}2018)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />

            <p className="about-item-content">
              Member, Texas Lt. Governor&apos;s Law Enforcement Advisory
              Committee (2017)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Member, Constable Education Committee, Justices of the Peace and
              Constables Association of Texas (1991{dash}2015)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Lifetime Member, South Texas JPCA (2014)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Lifetime Member, West Texas JPCA (2014)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Committee Member, Texas Supreme Court Rules of Civil Procedure
              Ancillary Proceedings Subcommittee (2009{dash}2011)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Member, Austin/Travis County Family Violence Task Force (2010
              {dash})
            </p>
          </li>

          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Member, TCLEOSE Rules of Civil Procedure Ancillary Proceedings
              Subcommittee (2007{dash}2009)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Lifetime Member, North Texas Justices of the Peace and Constables
              Association of Texas (2008{dash})
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Member, Central Texas Justices of the Peace and Constables
              Association of TX (2006)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Chair/Member, Constable&apos;s Legislative Committee, Justices of
              the Peace and Constables Association of Texas (2003{dash})
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Member, TCOLE Civil Process Proficiency Certification Committee
              (2002{dash})
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Board of Directors, Justices of the Peace and Constables
              Association of Texas (1997{dash})
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Member, Justice of the Peace and Constables Association of Texas
              (1980{dash})
            </p>
          </li>
        </ul>
      </div>
      <div className="about-community about-item">
        <h2>Helping the Community</h2>
        <ul>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Mentor, T.A. Brown Elementary School and Seedling Foundation
              Brooke Elementary School (1993{dash}2018)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Big Brothers and Big Sisters Association (2010{dash})
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Volunteer, Texas Parks & Wildlife Anger Education (2008{dash})
            </p>
          </li>

          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Volunteer Instructor, Travis County Project Options for Domestic
              Violence (2000{dash}2005)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Founding Member, President and Officer, Constable Precinct Five
              Constable&apos;s Association (1993{dash})
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Chair, CN5 Men As Allies, Austin/Travis County Family Violence
              Task Force
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Founder/Volunteer, Silver Watch Program - Meals on Wheels and More
            </p>
          </li>
        </ul>
      </div>
      <div className="about-awards about-item">
        <h2>Achievements and Awards</h2>
        <ul>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              &quot;A Great Place to Work&quot; Travis County Recognition (2019)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              40 Year Travis County Longevity Certificate (2018)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              National Night Out Rookie of the Year Award (2018)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              T.A. Vines Award, JPCA of Texas (2015)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Mothers Against Drunk Driving Commitment to Youth Award (2014)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Best Practices of the Year Award, JPCA of Texas (2013{dash}2014)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Deputy Constable of the Year, Justice of the Peace and Constables
              Association of Texas (1997, 2009, 2012)
            </p>
          </li>

          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Distinguished Commander Award, Travis County Constable, Precinct
              Five (2002)
            </p>
          </li>
        </ul>
      </div>
      <div className="about-education about-item">
        <h2>Training and Education</h2>
        <ul>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              TAC 254 Leadership, Texas Association of Counties (2018)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Constable Leadership Institute accreditation from Sam Houston
              State University, Constable Leadership College (2010)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Civil Process Proficiency Certification, TCLEOSE (2005)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Spanish Language Certification, Travis County (2003)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Constable Leadership College, Southwest State University (2002)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Instructor Certification, TCLEOSE (1995)
            </p>
          </li>
          <li>
            <FaStar className="icon-star" focusable="false" />
            <p className="about-item-content">
              Master Peace Officer License, TCLEOSE (1993)
            </p>
          </li>
        </ul>
      </div>
    </div>
  </Layout>
);

export default About;
